<template>
    <div class="sustain">
        <a href="javascript:void(0);" class="scrollT" ref="sustain"></a>
        <div class="sub-banner" v-if="bannerList.length > 0" :style="{'backgroundImage':'url('+ bannerList[0].pic +')'}">
			<div class="container">
				<div class="main">
					<h2 class="wow fadeInDown">{{ bannerList[0].title }}</h2>
					<div class="txt wow fadeInUp" v-html="bannerList[0].sub_title"></div>
				</div>
			</div>
		</div>
        <div class="inside-nav wow fadeInDown">
			<div class="container">
				<div class="list">
					<a href="javascript:void(0);" @click="goAnchor(0)">01 <br/>Goals and Strategies</a>
					<a href="javascript:void(0);" @click="goAnchor(1)">02 <br/>ESG Achievements</a>
					<a href="javascript:void(0);" @click="goAnchor(2)">03 <br/>DMEGC for Green</a>
					<a href="javascript:void(0);" @click="goAnchor(3)">04 <br/>Supply Chain Traceability</a>
                    <a href="javascript:void(0);" @click="goAnchor(4)">05 <br/>ESG News</a>
                    <a href="javascript:void(0);" @click="goAnchor(5)">06 <br/>Downloads</a>
				</div>
			</div>
		</div>
        <div class="mainbody">
            <div class="goals-box cell-box">
                <a href="javascript:void(0);" class="anchor" ref="goals"></a>
                <div class="container">
                    <h3 class="wow fadeInDown">Goals and Strategies</h3>
                    <div class="main wow fadeInUp">
                        <div class="box">
                            <div class="txt" v-html="goalsDet"></div>
                        </div>
                        <div class="swiper-container swiper-goals">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="item in goalsList" :key="item.id">
                                    <div class="pic" v-if="item.video != ''" @click="showVideopop(item.video)">
                                        <i class="player abs-cc"></i>
                                        <img :src="item.pic" alt="">
                                    </div>
                                    <div class="pic" v-else>
                                        <img :src="item.pic" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-pagination"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="esg-box cell-box">
                <a href="javascript:void(0);" class="anchor" ref="esg"></a>
                <div class="container">
                    <div class="top">
                        <h3 class="wow fadeInLeft">ESG Achievements</h3>
                        <div class="right wow fadeInRight">
                            <ul class="nav">
                                <li :class="esgIndex == index ? 'active': ''" v-for="(item, index) in achiveList" :key="item.id"  @click="esgIndex = index">{{ item.name }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="main">
                        <div class="right">
                            <div class="swiper-container swiper-esg" v-if="achiveList.length > 0">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide" v-for="item in achiveList[esgIndex].esgList" :key="item.id">
                                        <div class="main">
                                            <div class="pic">
                                                <img :src="item.pic" alt="">
                                            </div>
                                            <div class="box">
                                                <div class="title">{{ item.title }}</div>
                                                <div class="txt" v-html="item.detail"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-button-prev fcc"><i></i></div>
                                <div class="swiper-button-next fcc"><i></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="green-box cell-box">
                <a href="javascript:void(0);" class="anchor" ref="green"></a>
                <div class="container">
                    <h3 class="wow fadeInLeft">DMEGC for Green</h3>
                    <div class="right" v-if="greenList.length > 0">
                        <ul class="nav">
                            <li :class="greenIndex == index ? 'active':''" v-for="(item, index) in greenList" :key="item.id" 
                            @click="greenIndex = index">{{ item.name }}</li>
                        </ul>
                        <div class="txt" v-html="greenList[greenIndex].detail"></div>
                        <div class="swiper-container swiper-green">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="(item, index) in greenList[greenIndex].picList" :key="index">
                                    <div class="pic">
                                        <img :src="item" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-pagination" :class="greenList[greenIndex].picList.length <=1 ? 'swiper-pagination-opacity': ''"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="supply-box cell-box">
                <a href="javascript:void(0);" class="anchor" ref="supply"></a>
                <div class="container">
                    <div class="top">
                        <h3 class="wow fadeInLeft">Supply Chain Traceability</h3>
                        <div class="right wow fadeInRight">
                            <ul class="nav" v-if="supplyList.length > 0">
                                <li :class="supplyIndex == index ? 'active':''" v-for="(item, index) in supplyList" :key="item.id" 
                            @click="supplyIndex = index">{{ item.name }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="main" v-if="supplyList.length > 0">
                        <div class="swiper-container swiper-supply">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="(item, index) in supplyList[supplyIndex].picList" :key="index">
                                    <div class="pic">
                                        <img :src="item" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-pagination" :class="supplyList[supplyIndex].picList.length <=1 ? 'swiper-pagination-opacity': ''"></div>
                        </div>
                        <div class="txt" v-html="supplyList[supplyIndex].detail"></div>
                    </div>
                </div>
            </div>
            <div class="news-box cell-box">
                <a href="javascript:void(0);" class="anchor" ref="news"></a>
                <div class="container">
                    <div class="main">
                        <h3>ESG News</h3>
                        <div class="right">
                            <ul>
                                <li v-for="item in newsList" :key="item.id"><router-link :to="`/newsdet?id=${item.id}`">
                                    <div class="img">
                                        <img :src="item.pic" alt="">
                                    </div>
                                    <div class="name lineTwo">{{ item.title }}</div>
                                    <div class="time">{{ item.add_date }}</div>
                                    <div class="txt" v-html="item.detail.substr(0,200)+'...'"></div>
                                    <div class="more">Read More<i></i></div>
                                </router-link></li>
                            </ul>
                            <router-link to="/news?rmd=on&p=1" class="read-more wow fadeInUp">Read More<i></i></router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="load-box cell-box">
                <a href="javascript:void(0);" class="anchor" ref="load"></a>
                <div class="container">
                    <div class="main">
                        <div class="left">
                            <div class="year">{{ loadInfo.title }}</div>
                            <div class="txt">{{ loadInfo.detail }}</div>
                            <a :href="loadInfo.files" :download="loadInfo.title" target="_blank" class="load-btn fcc"><i></i>Downloads</a>
                        </div>
                        <div class="pic">
                            <img :src="loadInfo.pic" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="media-pop" v-show="showVideo">
            <div class="media-pop-main">
                <a href="javascript:void(0);" class="close" @click="closeVideo"></a>
                <video v-if="videoSrc !== ''" :src="videoSrc" controls autoplay></video>
            </div>
        </div>
    </div>
</template>
<script>
    import Swiper from 'swiper'
    import 'swiper/css/swiper.min.css'
    import { getBannerList, getSustainInfo, getSustainList, getSustainCateInfo, getNewsList } from '../../request/api'
    import _isMobile from '../../utils'
    export default {
        name:'Sustain',
        metaInfo:{
            title:"DMEGC Solar-Sustainability",
            meta: [{                 // set meta
                name: 'keyWords',
                content: 'DMEGC Solar'
            },{
                name:'description',
                content:'DMEGC Solar'
            }]
        },
        data() {
            return {
                bannerList: [],
                goalsDet:'',
                goalsList:[],
                esgIndex:0,
                achiveList:[],
                greenList:[],
                greenIndex:0,
                supplyList:[],
                supplyIndex:0,
                newsList:[],
                loadInfo:{
                    title:'',
                    detail:'',
                    files: '',
                    pic: ''
                },
                showVideo: false,
                videoSrc: '',
                isMobile: false
            }
        },
        mounted() {

            this.isMobile = _isMobile();
            this.$bus.$on('headNav',(data) => {
                this.goAnchor(data)
            });

            getBannerList({cate:'sustain'}).then( res => { //banner

                this.bannerList = res.data
            });

            getSustainInfo({id: 10000}).then( res => {

                this.goalsDet = res.data.detail;
            });

            getSustainList({cid: 10000}).then( res => { //ESG Achievements

                this.goalsList = res.data;
                this.$nextTick(()=>{

                    new Swiper('.swiper-goals', {
                        slidesPerView: 1,
                        spaceBetween: 10,
                        observer: true,
                        pagination:{
                            el: '.swiper-goals .swiper-pagination',
                            clickable: true
                        }
                    })
                })

            });

            getSustainCateInfo({pid: 10001}).then(res => { //ESG Achievements

                this.achiveList = res.data;
                this.esgList = res.data[0].esgList;
                this.$nextTick(()=>{

                    if(this.isMobile){

                        new Swiper('.swiper-esg', {
                            autoplay: true,
                            slidesPerView: 1,
                            spaceBetween: 1,
                            observer: true,
                            navigation: {
                                nextEl: '.swiper-esg .swiper-button-next',
                                prevEl: '.swiper-esg .swiper-button-prev',
                            }
                        })
                        } else {

                        new Swiper('.swiper-esg', {
                            autoplay: true,
                            slidesPerView: 2,
                            spaceBetween: 1,
                            observer: true,
                            navigation: {
                                nextEl: '.swiper-esg .swiper-button-next',
                                prevEl: '.swiper-esg .swiper-button-prev',
                            }
                        })
                    }
                });
            })

            //this.showEsgList(); //ESG Achievements

            getSustainCateInfo({pid: 10002}).then( res => { //DMEGC for Green

                this.greenList = res.data;
                this.$nextTick(()=>{

                    new Swiper('.swiper-green', {
                        slidesPerView: 1,
                        spaceBetween: 10,
                        observer: true,
                        pagination:{
                            el: '.swiper-green .swiper-pagination',
                            clickable: true
                        }
                    })
                });
            });

            getSustainCateInfo({pid: 10003}).then( res => { // Supply Chain Traceability

                this.supplyList = res.data;
                this.$nextTick(()=>{

                    new Swiper('.swiper-supply', {
                        slidesPerView: 1,
                        spaceBetween: 10,
                        observer: true,
                        pagination:{
                            el: '.swiper-supply .swiper-pagination',
                            clickable: true
                        }
                    })
                });
            });

            getNewsList({p:1, rows: 3, rmd: 'on'}).then( res => { //esg news

                this.newsList = res.data.newsList;
            });

            getSustainList({cid: 10013, rows: 1}).then(res => { //load

                const { title , detail, files, pic } = res.data;
                this.loadInfo.title = title;
                this.loadInfo.detail = detail;
                this.loadInfo.files = files;
                this.loadInfo.pic = pic;
            });
        },
        beforeDestroy() {

            this.$bus.$off('headNav');
        },
        methods: {

            async showEsgList() {

                await getSustainList({cid: 10004}).then( res => {
                
                    this.esgList.push(res.data);
                });

                await getSustainList({cid: 10005}).then( res => {
                
                    this.esgList.push(res.data);
                });

                await getSustainList({cid: 10006}).then( res => {
                
                    this.esgList.push(res.data);
                
                    this.$nextTick(()=>{

                        if(this.isMobile){

                            new Swiper('.swiper-esg', {
                                slidesPerView: 1,
                                spaceBetween: 1,
                                observer: true,
                                observeSlideChildren: true,
                                navigation: {
                                    nextEl: '.swiper-esg .swiper-button-next',
                                    prevEl: '.swiper-esg .swiper-button-prev',
                                }
                            })
                        } else {

                            new Swiper('.swiper-esg', {
                                slidesPerView: 2,
                                spaceBetween: 1,
                                observer: true,
                                observeSlideChildren: true,
                                navigation: {
                                    nextEl: '.swiper-esg .swiper-button-next',
                                    prevEl: '.swiper-esg .swiper-button-prev',
                                }
                            })
                        }
                    })
                });
            },
            goAnchor( num ){

                if( num === 0 ) {

                    this.$refs.goals.scrollIntoView({behavior: "smooth"});
                } else if( num === 1) {
                    
                    this.$refs.esg.scrollIntoView({behavior: "smooth"});
                }else if ( num === 2 ) {

                    this.$refs.green.scrollIntoView({behavior: "smooth"});
                }else if( num === 3 ) {

                    this.$refs.supply.scrollIntoView({behavior: "smooth"});
                }else if( num === 4 ) {

                    this.$refs.news.scrollIntoView({behavior: "smooth"});
                }else {

                    this.$refs.load.scrollIntoView({behavior: "smooth"});
                }
            },
            showVideopop (src) {

                if (src !== '') {

                    this.showVideo = true;
                    this.videoSrc = src;
                }
            },
            closeVideo () {

                this.showVideo = false;
                this.videoSrc = '';
            }
        }
    }
</script>
<style lang="scss" scoped>
.sustain{
    .goals-box{
        background: #F2F4F8;
        padding: 4.1667vw 0 5.625vw;
        .main{
            display: flex;
            margin-top: 2.6042vw;
        }
        .box{
            width: 31.25vw;
            height: 26.0417vw;
            box-sizing: border-box;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            .txt{
                width: 22.5vw;
            }
            /deep/p{
                font-size: 0.9375vw;
                line-height: 1.6667;
                color: rgba(0,0,0,0.8);
                font-weight: 300;
                margin-bottom: 0.8333vw;
                &:last-child{
                    margin-bottom: 0;
                }
            }

        }
        .swiper-goals{
            width: 50vw;
            .swiper-pagination{
                display: flex;
                justify-content: center;
                bottom: 1.3021vw;
            }
            /depp/.swiper-pagination-bullet{
                width: 8px;
                height: 8px;
                background: #AFBAC7 !important;
                border-radius: 50%;
                margin: 0 0.4167vw;
                opacity: 1;
            }
            /deep/.swiper-pagination-bullet-active{
                background: #E60011 !important;;
            }
        }
        .pic{
            position: relative;
        }
        img{
            display: block;
            width: 100%;
            height: 26.0417vw;
            object-fit: cover;
        }
        .player {
            display: block;
            cursor: pointer;
            width: 3.6458vw;
            height: 3.6458vw;
            background: url("../../assets/images/player.png") no-repeat;
            background-size: cover;
        }
    }
    .esg-box{
        padding: 4.6875vw 0 5.2083vw;
        .top{
            display: flex;
            justify-content: space-between;
        }
        .nav{
            display: flex;
            li{
                margin-right: 2.6042vw;
                font-size: .9896vw;
                line-height: 2.3438vw;
                color: rgba(0, 0, 0, .6);
                cursor: pointer;
            }
            .active{
                border-bottom: 2px solid #E60011;
            }
        }
        .main{
            margin-top: 2.8125vw;
            display: flex;
            justify-content: flex-end;
        }
    }
    .swiper-esg{
        height: 14.5834vw;
        padding-bottom: 4.1667vw;
        .main{
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #F2F4F8;
            padding: 1.0417vw;
            margin-top: 0;
        }
        .pic{
            width: 12.5vw;
        }
        .box{
            width: 13.8021vw;
        }
        .title{
            font-size: 1.25vw;
            line-height: 1.2083;
            color: rgba(0,0,0,0.8);
        }
        .txt{
            font-size: 0.8333vw;
            line-height: 1.625;
            color: rgba(0,0,0,0.6);
            margin-top: 0.8333vw;
            font-weight: 300;
        }
        .swiper-button-prev,
        .swiper-button-next{
            left: 0;
            top: auto;
            margin: 0;
            bottom: 0;
            width: 2.6042vw;
            height: 2.6042vw;
            background: #232323;
            &::after{
                display: none;
            }
            i{
                display: block;
                width: 1.25vw;
                height: 1.25vw;
                background: url(../../assets/images/arrowR.png) no-repeat;
                background-size: cover;
                transform: rotate(180deg);
            }
            &:hover{
                background: #E60011;
            }
        }
        .swiper-button-next{
            margin-left: calc(2.6042vw + 2px);
            i{
                transform: rotate(0deg);
            }
        }
    }
    .green-box{
        background: #F2F4F8;
        padding: 4.6875vw 0 6.25vw;
        .container{
            display: flex;
            justify-content: space-between;
        }
        .right{
            width: 60.4688vw;
        }
        .nav{
            display: flex;
            li{
                margin-right: 2.6042vw;
                font-size: .9896vw;
                line-height: 2.3438vw;
                color: rgba(0, 0, 0, .6);
                cursor: pointer;
            }
            .active{
                border-bottom: 2px solid #E60011;
            }
        }
        .txt{
            margin-top: 2.7604vw;
            font-size: 1.1979vw;
            line-height: 1.4782;
            color: rgba(0,0,0,0.6);
        }
    }
    .swiper-green{
        margin-top: 2.5vw;
        .swiper-pagination{
            display: flex;
            justify-content: center;
            bottom: 1.3021vw;
        }
        /deep/.swiper-pagination-bullet{
            width: 8px;
            height: 8px;
            background: #AFBAC7 !important;;
            border-radius: 50%;
            margin: 0 0.4167vw;
            opacity: 1;
        }
        /deep/ .swiper-pagination-opacity{
            opacity: 0;
        }
        /deep/.swiper-pagination-bullet-active{
            background: #E60011 !important;;
        }
    }
    .supply-box{
        padding: 5.625vw 0 7.2917vw;
        .top{
            display: flex;
        }
        .nav{
            margin-left: 6.1458vw;
            display: flex;
            li{
                margin-right: 2.6042vw;
                font-size: .9896vw;
                line-height: 2.3438vw;
                color: rgba(0, 0, 0, .6);
                cursor: pointer;
            }
            .active{
                border-bottom: 2px solid #E60011;
            }
        }
        .main{
            padding-bottom: 1.7708vw;
            border-bottom: 0.2083vw solid #E6E6E6;
        }
        .txt{
            text-align: center;
            margin: 2.0833vw auto 0;
            width: 67.3438vw;
            font-size: 1.1979vw;
            line-height: 1.4782;
            color: rgba(0,0,0,0.6);
        }
    }
    .swiper-supply{
        margin-top: 3.75vw;
        .swiper-pagination{
            display: flex;
            justify-content: center;
            bottom: 1.3021vw;
        }
        /deep/.swiper-pagination-bullet{
            width: 8px;
            height: 8px;
            background: #AFBAC7 !important;;
            border-radius: 50%;
            margin: 0 0.4167vw;
            opacity: 1;
        }
        /deep/ .swiper-pagination-opacity{
            opacity: 0;
        }
        /deep/.swiper-pagination-bullet-active{
            background: #E60011 !important;;
        }
    }
    .news-box{
        background: #F2F4F8;
        padding: 5.0521vw 0 5.7292vw;
        .main{
            display: flex;
            justify-content: space-between;
        }
        .right{
            width: 60.4167vw;
        }
        ul{
            display: flex;
        }
        li{
            width: 18.75vw;
            margin-right: 2.0833vw;
            &:nth-child(3n){
                margin-right: 0;
            }
            a{
                display: block;
            }
        }
        .img{
            display: block;
            height: 12.1875vw;
            overflow: hidden;
        }
        img{
            display: block;
            width: 100%;
            height: 12.1875vw;
            object-fit: cover;
        }
        .name{
            font-size: 1.3542vw;
            line-height: 1.7708vw;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.8);
        }
        .time{
            font-size: 0.8333vw;
            line-height: 1.0417vw;
            color: rgba(0, 0, 0, 0.8);
            font-weight: 600;
            font-style: italic;
            margin-top: 0.625vw;
        }
        .txt{
            font-size: 0.7813vw;
            line-height: 1.1979vw;
            color: rgba(0, 0, 0, 0.6);
            margin-top: 2.0833vw;
        }
        .more{
            display: flex;
            height: 1.7708vw;
            align-items: center;
            font-size: 14px;
            color: #E60011;
            margin-top: 1.7708vw;
            i{
                display: block;
                width: 0.8333vw;
                height: 0.8333vw;
                background: url('../../assets/images/arrowRR.png') no-repeat;
                background-size: cover;
                margin-left: 0.9375vw;
            }
        }
        .read-more{
            margin-top: 1.875vw;
        }
    }
    .load-box{
        background: url(../../assets/img/load_bg.jpg) no-repeat center center;
        background-size: cover;
        box-sizing: border-box;
        height: 28.125vw;
        padding-top: 6.4062vw;
        .main{
            width: 38.75vw;
            margin: 0 auto;
            display: flex;
            position: relative;
            &::before{
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: rgba(0, 0, 0, 0.2);
                backdrop-filter: blur(20px);
                -webkit-backdrop-filter: blur(20px);
            }
        }
        .left{
            box-sizing: border-box;
            width: 27.0833vw;
            padding: 1.9792vw 2.7083vw 0;
            position: relative;
            z-index: 10;
        }
        .year{
            font-size: 3.0729vw;
            line-height: 1.2034;
            color: #fff;
            font-weight: 700;
        }
        .txt{
            font-size: 1.1979vw;
            line-height: 1.3043;
            color: #fff;
            margin-top: 0.625vw;
            font-weight: 700;
        }
        .load-btn{
            box-sizing: border-box;
            width: 9.375vw;
            height: 2.6042vw;
            border: 1px solid #fff;
            font-size: 0.8333vw;
            margin-top: 2.0833vw;
            color:#fff;
            transition: .3s linear;
            &:hover{
                background: #E60011;
                border-color: #E60011;
            }
        }
        i{
            display: block;
            width: 1.25vw;
            height: 1.25vw;
            background: url(../../assets/images/pdf_icon.png) no-repeat;
            background-size: cover;
            margin-right: 0.5208vw;
        }
        .pic{
            width: 11.5625vw;
            border: 0.2083vw solid #2ba037;
            position: relative;
            z-index: 10;
        }
    }
    .media-pop {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 999;
        .media-pop-main {
            width: 960px;
            height: 540px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
            video {
                display: block;
                width: 100%;
                height: 540px;
            }
            .close {
                position: absolute;
                right: -30px;
                top: -30px;
                width: 24px;
                height: 24px;
                background: url("../../assets/images/close.png") no-repeat;
                background-size: cover;
            }
        }
    }
}
@media screen and (max-width: 750px)  {
    .sustain{
        .goals-box{
            padding: .8rem 0;
            .main{
                display: block;
                margin-top: .3rem;
            }
            .box{
                width: 100%;
                height: auto;
                padding: .6rem .4rem;
                .txt{
                    width: 100%;
                }
                /deep/p{
                    font-size: .26rem;
                    margin-bottom: .2rem;
                }
            }
            .swiper-goals{
                width: 100%;
                margin-top: .5rem;
            }
            img{
                height: 3.6rem;
            }
            .player{
                width: .64rem;
                height: .64rem;
            }
        }
        .esg-box{
            padding: .8rem 0;
            .top{
                display: block;
            }
            .nav{
                li{
                    font-size: .3rem;
                    margin-right: .4rem;
                    line-height: .8rem;
                }
            }
            .main{
                margin-top: .2rem;
                display: block;
            }
        }
        .swiper-esg{
            padding-bottom: 0;
            height: 2.8rem;
            .main{
                padding: .3rem;
                margin-top: 0 !important;
            }
            .pic{
                width: 2.4rem;
            }
            .box{
                width: 3.6rem;
            }
            .title{
                font-size: .36rem;
            }
            .txt{
                font-size: .26rem;
                margin-top: .2rem;
            }
        }
        .green-box{
            padding: .8rem 0;
            .container{
                display: block;
            }
            .right{
                width: 100%;
            }
            .nav{
                li{
                    margin-right: .4rem;
                    line-height: .8rem;
                    font-size: .26rem;
                }
            }
            .txt{
                font-size: .26rem;
                margin-top: .5rem;
            }
        }
        .swiper-green{
            margin-top: .5rem;
        }
        .supply-box{
            padding: .8rem 0;
            .top{
                display: block;
            }
            .nav{
                margin-left: 0;
                li{
                    margin-right: .4rem;
                    font-size: .26rem;
                    line-height: .8rem;
                }
            }
            .main{
                padding-bottom: .6rem;
                border-bottom-width: 1px;
            }
            .txt{
                margin-top: .3rem;
                width: 100%;
                font-size: .26rem;
            }
        }
        .swiper-supply{
            margin-top: .5rem;
        }
        .news-box{
            padding: .8rem 0;
            .main{
                display: block;
            }
            .right{
                width: 100%;
            }
            ul{
                display: block;
            }
            li{
                width: 100%;
                margin: 0 0 .5rem;
            }
            .img,
            img{
                height: 4.48rem;
            }
            .name{
                font-size: .36rem;
                line-height: 1.5;
                margin-top: .3rem;
            }
            .time{
                font-size: .24rem;
                line-height: 1.2;
                margin-top: .2rem;
            }
            .txt{
                font-size: .26rem;
                line-height: 1.75;
                margin-top: .3rem;
            }
            .more{
                margin-top: .3rem;
                font-size: .26rem;
                i{
                    width: .32rem;
                    height: .32rem;
                    margin-left: .2rem;
                }
            }
        }
        .load-box{
            height: auto;
            padding: .8rem 0;
            .main{
                width: 100%;
            }
            .left{
                width: 4.6rem;
                padding: .3rem .3rem 0;
            }
            .year{
                font-size: .36rem;
            }
            .txt{
                font-size: .26rem;
                margin-top: .2rem;
            }
            .load-btn{
                width: 2.4rem;
                height: .8rem;
                font-size: .28rem;
                margin-top: .5rem;
            }
            i{
                width: .36rem;
                height: .36rem;
                margin-right: .2rem;
            }
            .pic{
                width: 2.22rem;
                border-width: .04rem;
            }
        }
    }
}
</style>
